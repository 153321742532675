@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS for animations */
@layer utilities {
  .animation-delay-150 {
    animation-delay: 150ms;
  }
  .animation-delay-300 {
    animation-delay: 300ms;
  }
  .animation-delay-450 {
    animation-delay: 450ms;
  }
  .animation-delay-600 {
    animation-delay: 600ms;
  }
  .animation-delay-750 {
    animation-delay: 750ms;
  }
  .animation-delay-900 {
    animation-delay: 900ms;
  }
}

/* Optional: Add some base styles */
@layer base {
  body {
    @apply bg-[#0f0c29] text-white;
  }
}
